// Generated by Framer (450d190)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {hrZJD6at9: {hover: true}, J_QvQjSJD: {hover: true}};

const cycleOrder = ["J_QvQjSJD", "hrZJD6at9"];

const serializationHash = "framer-Vl3nH"

const variantClassNames = {hrZJD6at9: "framer-v-bhx6ov", J_QvQjSJD: "framer-v-nsk3uh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 2": "hrZJD6at9", Button: "J_QvQjSJD"}

const getProps = ({background, height, id, link, tap, width, ...props}) => { return {...props, HnSIJ449d: link ?? props.HnSIJ449d, TEyYcafUw: background ?? props.TEyYcafUw ?? "var(--token-d2f96dda-5f24-4f5e-a9e1-1a4a0b0c1c5e, rgb(0, 90, 140))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "J_QvQjSJD", w4UMwmYYu: tap ?? props.w4UMwmYYu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string;tap?: any;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HnSIJ449d, w4UMwmYYu, TEyYcafUw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "J_QvQjSJD", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap11p78b2 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (w4UMwmYYu) {
const res = await w4UMwmYYu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={HnSIJ449d} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-nsk3uh", className, classNames)} framer-3bh6sd`} data-framer-name={"Button"} data-highlight data-reset={"button"} layoutDependency={layoutDependency} layoutId={"J_QvQjSJD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap11p78b2} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: TEyYcafUw, ...style}} variants={{"hrZJD6at9-hover": {backgroundColor: "var(--token-673b58ce-3b1c-4945-accf-c1d45d926bd0, rgb(0, 43, 89))"}, "J_QvQjSJD-hover": {backgroundColor: "var(--token-673b58ce-3b1c-4945-accf-c1d45d926bd0, rgb(0, 35, 73))"}, hrZJD6at9: {backgroundColor: "var(--token-0c5d6188-2305-449f-bacc-6b42117b54dc, rgb(242, 242, 242))"}}} {...addPropertyOverrides({"hrZJD6at9-hover": {"data-framer-name": undefined}, "J_QvQjSJD-hover": {"data-framer-name": undefined}, hrZJD6at9: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Vl3nH.framer-3bh6sd, .framer-Vl3nH .framer-3bh6sd { display: block; }", ".framer-Vl3nH.framer-nsk3uh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; min-height: 46px; min-width: 71px; padding: 10px 20px 10px 20px; position: relative; text-decoration: none; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Vl3nH.framer-nsk3uh { gap: 0px; } .framer-Vl3nH.framer-nsk3uh > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-Vl3nH.framer-nsk3uh > :first-child { margin-left: 0px; } .framer-Vl3nH.framer-nsk3uh > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45.5
 * @framerIntrinsicWidth 71
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"hrZJD6at9":{"layout":["auto","auto"]},"j0eLLj860":{"layout":["auto","auto"]},"Yb7o9ZEvw":{"layout":["auto","auto"]}}}
 * @framerVariables {"HnSIJ449d":"link","w4UMwmYYu":"tap","TEyYcafUw":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerh1IaehVDa: React.ComponentType<Props> = withCSS(Component, css, "framer-Vl3nH") as typeof Component;
export default Framerh1IaehVDa;

Framerh1IaehVDa.displayName = "Button / Plain - Active";

Framerh1IaehVDa.defaultProps = {height: 45.5, width: 71};

addPropertyControls(Framerh1IaehVDa, {variant: {options: ["J_QvQjSJD", "hrZJD6at9"], optionTitles: ["Button", "Variant 2"], title: "Variant", type: ControlType.Enum}, HnSIJ449d: {title: "Link", type: ControlType.Link}, w4UMwmYYu: {title: "Tap", type: ControlType.EventHandler}, TEyYcafUw: {defaultValue: "var(--token-d2f96dda-5f24-4f5e-a9e1-1a4a0b0c1c5e, rgb(0, 90, 140)) /* {\"name\":\"Primary light\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(Framerh1IaehVDa, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})